import "./Styles.css";
import React from "react";
import moment from "moment";
import { Mic, SendMsg } from "../../Icons";
import {
  Translation,
  placeholder,
  ListenResp,
  OptionSelect,
  ShareFile,
  ShareLocation,
  ShowOptions,
  skip,
} from "../../Translation/index";
import { IconButton } from "@mui/material";


const btnStyle = {
  // backgroundColor: "#ededed",
  border: "1px solid #f5e6f5",
  borderRadius: "50%",
  padding: "5px",
  margin: "3px",
};
export default function MicAndTextfield(props: any) {
  const {
    inputField,
    setLanguageOptions,
    selectedLanguage,
    input,
    handleSendMessage,
    setInput,
    micBtn,
    recognitionSupported,
    startRecognition,
    requestPermission,
    sendBtn,
    getUserLocation,
    fileUpload,
    handleFileUpload,
    location,
    setBackOptions,
    backOptions,
    setHideInput,
    setInputField,
    handleInputChange,
    setHospitalView,
    setSearchTerm,
    setFileUpload,
    setAutoPlay,
    apiCall,
    setMessages,
    setLoading,
  } = props;

  const handleSkipUpload = () => {
    setMessages((prevChats: any) => [
      ...prevChats,
      {
        text: (skip as any)[selectedLanguage],
        sender: "user",
        time: moment().format("h:mm A"),
      },
    ]);
    setLoading(true);
    setFileUpload(false);
    setInputField(true);
    setAutoPlay(false);
    setBackOptions(false);
    apiCall("=grevSkip=", false, "text");
  };

  const handleMic = () => {
    recognitionSupported ? startRecognition() : requestPermission();
  };

  return (
    <>
      <div className="BoxSentMSG">
        {/* loaction */}
        {location && (
          <div
            className="input-upload"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button onClick={getUserLocation} className="file-label">
              {(ShareLocation as any)[selectedLanguage]}
            </button>
          </div>
        )}

        {/* file upload */}
        {fileUpload && (
          <div
            className="input-upload"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
              <label htmlFor="fileInput" className="file-label">
                <input
                  type="file"
                  id="fileInput"
                  // className="file-input"
                  onChange={handleFileUpload}
                  style={{
                    display: "none",
                  }}
                />
                {(ShareFile as any)[selectedLanguage]}
              </label>
            

            <button
              className="file-label"
              onClick={() => {
                handleSkipUpload();
              }}
            >
              {(skip as any)[selectedLanguage]}
            </button>
          </div>
        )}

        {backOptions && (
          <div
            className="input-upload"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              className="file-label"
              onClick={() => {
                setHideInput(true);
              }}
            >
              {(ShowOptions as any)[selectedLanguage]}
            </button>
            <img
              src="close.png"
              width={15}
              height={15}
              alt="close"
              className="close"
              style={{ padding: "10px" }}
              onClick={() => {
                setHideInput(false);
                setInputField(true);
                setBackOptions(false);
                setHospitalView(false);
                setSearchTerm("");
              }}
            />
          </div>
        )}
        {inputField && (
          <>
            <button
              // style={{
              //   width: "fitContent",
              //   height: "42px",
              //   marginRight: "5px",
              //   marginLeft: "5px",
              //   padding: "10px",
              //   border: "none",
              //   borderRadius: "20px",
              //   fontSize: "20px",
              //   cursor: "pointer",
              //   transition: "background-color 0.3s",
              // }}
              className="langBtn"
              onClick={() => {
                setLanguageOptions(true);
              }}
              // value={selectedLanguage}
            >
              {selectedLanguage[0].toUpperCase() + selectedLanguage.slice(1)}
            </button>

            <input
              placeholder={(placeholder as any)[selectedLanguage]}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              className="input"
            />

            {micBtn && (
              <IconButton style={btnStyle} size="small" onClick={handleMic}>
                {Mic}
              </IconButton>
            )}

            {sendBtn && (
              <IconButton
                size="small"
                onClick={handleSendMessage}
                style={btnStyle}
              >
                {SendMsg}
              </IconButton>
            )}
          </>
        )}
      </div>
    </>
  );
}
