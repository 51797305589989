import "./App.css";
import React  from "react"
import Chatbot from "./ChatBot/Body/Chatbot";

function App() {
  return (
    <div
      style={{
        position: "relative",
        // width: "100%",
        display: "flex",
        justifyContent: "center",
        height:"100%"
      }}
    >
      <Chatbot />
    </div>
  );
}

export default App;
