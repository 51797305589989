export const languageList = [
  { label: "English", value: "en" },
  { label: "हिंदी", value: "hi" },
  { label: "తెలుగు", value: "te" },
  { label: "தமிழ்", value: "ta" },
  { label: "বাঙালি", value: "bn" },
  { label: "ಕನ್ನಡ", value: "kn" },
  { label: "اردو", value: "ur" },
  { label: "ગુજરાતી", value: "gu" },
  { label: "ଓଡିଆ", value: "or" },
  { label: "मराठी", value: "mr" },
  { label: "ਪੰਜਾਬੀ", value: "pa" },
  { label: "অসমীয়া", value: "as" },
  { label: "മലയാളം", value: "ml" },
  
];
