import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { VoiceMic } from "../../Icons";
import {
  ContainerVoice,
  CloseIcon,
  VoiceRecognitionContainer,
  VoiceRecognitionSpan,
} from "./Styles";

import { ListeningTrans } from "../../Translation";

const Voice = (props: any) => {
  const {
    setVoiceRecognition,
    setInputField,
    selectedLanguage,
    transcript,
    setTranscriptTxt,
    stopRecognition,
  } = props;

  const [dots, setDots] = useState(3);

  const closeRecognition = () => {
     stopRecognition()
    setVoiceRecognition(false);
    setInputField();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((dots) => {
        return dots < 3 ? dots + 1 : 0;
      });
    }, 500);

    return () => {
      clearInterval(interval);
      setTranscriptTxt("");
    };
  }, []);

  return (
    <ContainerVoice className={"ContainerVoice"}>
      <CloseIcon
        className={"CloseIcon"}
        src="close.png"
        width={15}
        height={15}
        alt="close"
        onClick={closeRecognition}
      />

      <div style={{ height: "100%", flexGrow: 1, paddingBottom: "30px" }}>
        <div
          style={{
            margin: "auto",
            textAlign: "justify",
            padding: "0px 15px 0px 15px",
          }}
        >
          <p style={{ textAlign: "center", fontSize: "20px" }}>
            {transcript == ""
              ? (ListeningTrans as any)[selectedLanguage] + ".".repeat(dots)
              : transcript}
          </p>
        </div>
      </div>

      <div
        style={{
          height: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          width: "100%",
        }}
      >
        <VoiceRecognitionContainer className={"VoiceRecognitionContainer"}>
          <VoiceRecognitionSpan
            className={"VoiceRecognitionSpan"}
          ></VoiceRecognitionSpan>
          <VoiceRecognitionSpan
            className={"VoiceRecognitionSpan"}
          ></VoiceRecognitionSpan>
          <VoiceRecognitionSpan
            className={"VoiceRecognitionSpan"}
          ></VoiceRecognitionSpan>
          <VoiceRecognitionSpan
            className={"VoiceRecognitionSpan"}
          ></VoiceRecognitionSpan>
          <VoiceRecognitionSpan
            className={"VoiceRecognitionSpan"}
          ></VoiceRecognitionSpan>
          {VoiceMic}
          <VoiceRecognitionSpan
            className={"VoiceRecognitionSpan"}
          ></VoiceRecognitionSpan>
          <VoiceRecognitionSpan
            className={"VoiceRecognitionSpan"}
          ></VoiceRecognitionSpan>
          <VoiceRecognitionSpan
            className={"VoiceRecognitionSpan"}
          ></VoiceRecognitionSpan>
          <VoiceRecognitionSpan
            className={"VoiceRecognitionSpan"}
          ></VoiceRecognitionSpan>
          <VoiceRecognitionSpan
            className={"VoiceRecognitionSpan"}
          ></VoiceRecognitionSpan>
        </VoiceRecognitionContainer>
      </div>
    </ContainerVoice>
  );
};

Voice.propTypes = {
  setVoiceRecognition: PropTypes.func.isRequired,
  setInputField: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  transcript: PropTypes.string,
  pauseAudio: PropTypes.func,
  setTranscriptTxt: PropTypes.func,
  stopRecognition: PropTypes.func,
};

export default Voice;
