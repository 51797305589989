import React from "react";
import { languageList } from "./LanguageList";
import { LanguageHeader } from "../../Translation";

function LanguageOptions(props: any) {
  const {
    languageOptions,
    setLanguageOptions,
    handleLanguageChange,
    selectedLanguage,
  } = props;
  return (
    <>
      {languageOptions && (
        <div className="container-lang">
          <div className="closeIcon">
            <img
              src="close.png"
              width={15}
              height={15}
              alt="close"
              onClick={() => {
                setLanguageOptions(false);
              }}
            />
          </div>
          <span
            style={{
              textAlign: "center",
              padding: "10px 0px",
              fontSize: "20px",
              fontWeight:700
            }}
          >
            {LanguageHeader[selectedLanguage]}
          </span>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap", 
              cursor: "pointer",
              justifyContent: "space-evenly",
            }}
          >
            {languageList &&
              languageList.length > 0 &&
              languageList.map((val, index) => (
                <span
                  key={index}
                  id={val.value}
                  onClick={() => handleLanguageChange(val.value)}
                  className={
                    val.value === selectedLanguage
                      ? "selected-language"
                      : "other-language"
                  }
                >
                  {val.label}
                </span>
              ))}
          </div>
        </div>
      )}
    </>
  );
}

export default LanguageOptions;