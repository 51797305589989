import React, { useState, useEffect } from "react";
import "./styles.css";
import { Feedback, Save, Cancel, FeedBackInput } from "../Translation";
import { FeedbackApi } from "../Api";
function DisLike(props: any) {
  const {
    handleToggle,
    setDisLikeView,
    disLikeView,
    messageIndex,
    selectedLanguage,
    answerId,
  } = props;
  const [textareaDisabled, setTextareaDisabled] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [saveBtnStyle, SetSaveBtnStyle] = useState("btn disable");
  const [typing, setTyping] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleFeedbackTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFeedbackText(event.target.value);
    setTyping(true);
  };

  const debouncedHandleFeedbackTextChange = debounce(
    handleFeedbackTextChange,
    400
  );

  const handleSaveFeedback = () => {
    setDisLikeView(false);
    handleToggle(messageIndex);
    FeedbackApi(answerId, false, feedbackText);
  };

  const handleCancelFeedback = () => {
    setDisLikeView(false);
  };

  useEffect(() => {
    if (feedbackText.trim().length < 4) {
      setTextareaDisabled(true);
      SetSaveBtnStyle("btn disable");
    } else {
      setTextareaDisabled(false);
      SetSaveBtnStyle("btn save");
    }
  }, [feedbackText]);

  // useEffect(() => {
  //   if (feedbackText.length < 250) {
  //     setErrorMessage(true);
  //   } else {
  //     setErrorMessage(false);
  //   }
  // }, [feedbackText]);
  return (
    <>
      {disLikeView && (
        <div className="backDrop">
          <div className="textContainer">
            <span className="headerFeedback1">
              {(Feedback as any)[selectedLanguage]}
            </span>

            <textarea
              className="textarea"
              cols={20}
              rows={4}
              placeholder={(FeedBackInput as any)[selectedLanguage]}
              onChange={(e) => debouncedHandleFeedbackTextChange(e)}
            />

            <div className="buttonGroup">
              <button className="btn cancel" onClick={handleCancelFeedback}>
                {(Cancel as any)[selectedLanguage]}
              </button>
              <button
                className={saveBtnStyle}
                onClick={handleSaveFeedback}
                disabled={textareaDisabled}
              >
                {(Save as any)[selectedLanguage]}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DisLike;
