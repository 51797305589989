import React from "react";
import TextFieldAndMic from "../ChatContent/ChatContent";
import {
  Container,
  TopLogoHeader,
  LogoImage,
  Footer,
  FooterLink,
} from "./Styles";

import { AMI, Online } from "../../Translation";

const Chatbot = () => {
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>("en");

  return (
    <Container className={"Container"}>
      {/* <TopLogoHeader className={"TopLogoHeader"}>
        <LogoImage className={"LogoImage"} src="a_logo.png" alt="header" />
        <div style={{ marginLeft: "20px" }}>
          <p
            style={{
              fontSize: "large",
              fontWeight: 700,
            }}
          >
            {(AMI as any)[selectedLanguage]}
          </p>
          <p style={{ marginTop: "-14px" }}>
            {(Online as any)[selectedLanguage]}
          </p>
        </div>
      </TopLogoHeader> */}
      <TextFieldAndMic Lang={setSelectedLanguage} />
      <Footer className={"Footer"}>
        {/* <span>
          Powered by
          <FooterLink className={"FooterLink"} href="https://corover.ai/">
            <img
              src="CoRover.png"
              alt="corover"
              width="50px"
              style={{ marginBottom: "-2px" }}
            />
          </FooterLink>
        </span> */}
      </Footer>
    </Container>
  );
};

export default Chatbot;
