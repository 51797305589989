import React from "react";
import { Hospital } from "../../Translation";

function HospitalOptions(props: any) {
  const {
    HospitalView,
    HospitalData,
    setHospitalView,
    selectedLanguage,
    setSearchTerm,
  } = props;

  const { label, distance, contactNo, address } = HospitalData;
  const styleHead = { display: "flex", gap: "15px", alignItems: "center" };
  const styleDetail = {
    backgroundColor: "#dfb0df",
    borderRadius: "24px",
    padding: "5px",
  };

  return (
    <>
      {HospitalView && (
        <div className="HospitalOptions">
          <div className="closeIcon">
            <img
              src="close.png"
              width={15}
              height={15}
              alt="close"
              className="close"
              onClick={() => {
                setHospitalView(false);
                setSearchTerm("");
              }}
            />
          </div>

          <span className="optionHeader">
            {(Hospital as any)[selectedLanguage][0]}
          </span>
          <div className="Hospitaldetails">
            <p style={styleHead}>
              <img src="name.svg" style={styleDetail} />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "900",
                  justifySelf: "center",
                  wordBreak: "break-all",
                }}
              >
                {label.toUpperCase()}
              </span>
            </p>
            <p style={styleHead}>
              <img src="distance.svg" style={styleDetail} />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  justifySelf: "center",
                  wordBreak: "break-all",
                }}
              >
                {distance.toUpperCase()}
              </span>
            </p>
            <p style={styleHead}>
              <img src="contact.svg" style={styleDetail} />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  justifySelf: "center",
                  wordBreak: "break-all",
                }}
              >
                {contactNo === "-" ? "N/A" : contactNo.toUpperCase()}
              </span>
            </p>
            <p style={styleHead}>
              <img src="location.svg" style={styleDetail} />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  justifySelf: "center",
                  wordBreak: "break-all",
                }}
              >
                {address.toUpperCase()}
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
}
export default HospitalOptions;
