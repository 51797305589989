export const Translation = {
  en: {
    greeting: "Hi! I am Axis My India",
    goodbye:
      "I can help you with the following services. You can choose from the following options or just ask any question:",
    scheme: "Government Schemes",
    grievance: "Submit a Need",
  },
  hi: {
    greeting: "नमस्ते! मैं एक्सिस माई इंडिया हूं",
    goodbye:
      "मैं निम्नलिखित सेवाओं में आपकी सहायता कर सकती हूँ। आप निम्नलिखित विकल्पों में से चुन सकते हैं या कोई भी प्रश्न पूछ सकते हैं:",
    scheme: "सरकारी योजनाएं",
    grievance: "आवश्यकता सबमिट करें",
  },
  te: {
    greeting: "హాయ్! నేను యాక్సిస్ మై ఇండియా",
    goodbye:
      "కింది సేవలలో నేను మీకు సహాయం చేయగలను. మీరు ఈ క్రింది ఎంపికల నుండి ఎంచుకోవచ్చు లేదా ఏదైనా ప్రశ్న అడగవచ్చు:",
    scheme: "ప్రభుత్వ పథకాలు",
    grievance: "ఆవశ్యకతను సమర్పించండి",
  },
  ta: {
    greeting: "வணக்கம்! நான் ஆக்சிஸ் மை இந்தியா",
    goodbye:
      "பின்வரும் சேவைகளில் நான் உங்களுக்கு உதவ முடியும். பின்வரும் விருப்பங்களிலிருந்து நீங்கள் தேர்வு செய்யலாம் அல்லது ஏதேனும் கேள்விகளைக் கேட்கலாம்:",
    scheme: "அரசு திட்டங்கள்",
    grievance: "தேவையை சமர்ப்பிக்கவும்",
  },
  bn: {
    greeting: "ওহে! আমি অ্যাক্সিস মাই ইন্ডিয়া",
    goodbye:
      "আমি আপনাকে নিম্নলিখিত পরিষেবাগুলির সাথে সাহায্য করতে পারি৷ আপনি নিম্নলিখিত বিকল্পগুলি থেকে চয়ন করতে পারেন বা কেবল যে কোনও প্রশ্ন জিজ্ঞাসা করতে পারেন:",
    scheme: "সরকারী স্কিম",
    grievance: "প্রয়োজনীয়তা জমা দিন",
  },
  kn: {
    greeting: "ನಮಸ್ತೆ! ನಾನು ಆಕ್ಸಿಸ್ ಮೈ ಇಂಡಿಯಾ",
    goodbye:
      "ಕೆಳಗಿನ ಸೇವೆಗಳಲ್ಲಿ ನಾನು ನಿಮಗೆ ಸಹಾಯ ಮಾಡಬಹುದು. ನೀವು ಈ ಕೆಳಗಿನ ಆಯ್ಕೆಗಳಿಂದ ಆಯ್ಕೆ ಮಾಡಬಹುದು ಅಥವಾ ಯಾವುದೇ ಪ್ರಶ್ನೆಯನ್ನು ಕೇಳಬಹುದು:",
    scheme: "ಸರ್ಕಾರದ ಯೋಜನೆಗಳು",
    grievance: "ಅಗತ್ಯವನ್ನು ಸಲ್ಲಿಸಿ",
  },
  ml: {
    greeting: "ഹായ്! ഞാൻ ആക്സിസ് മൈ ഇന്ത്യയാണ്",
    goodbye:
      "ഇനിപ്പറയുന്ന സേവനങ്ങളിൽ എനിക്ക് നിങ്ങളെ സഹായിക്കാനാകും. നിങ്ങൾക്ക് ഇനിപ്പറയുന്ന ഓപ്ഷനുകളിൽ നിന്ന് തിരഞ്ഞെടുക്കാം അല്ലെങ്കിൽ എന്തെങ്കിലും ചോദ്യം ചോദിക്കാം:",
    scheme: "സർക്കാർ പദ്ധതികൾ",
    grievance: "ആവശ്യം സമർപ്പിക്കുക",
  },
  mr: {
    greeting: "हाय! मी Axis My India आहे",
    goodbye:
      "मी तुम्हाला खालील सेवांमध्ये मदत करू शकतो. तुम्ही खालील पर्यायांमधून निवडू शकता किंवा कोणताही प्रश्न विचारू शकता:",
    scheme: "सरकारी योजना",
    grievance: "आवश्यकता सबमिट करा",
  },
  gu: {
    greeting: "હાય! હું એક્સિસ માય ઇન્ડિયા છું",
    goodbye:
      "હું તમને નીચેની સેવાઓમાં મદદ કરી શકું છું. તમે નીચેના વિકલ્પોમાંથી પસંદ કરી શકો છો અથવા ફક્ત કોઈપણ પ્રશ્ન પૂછી શકો છો:",
    scheme: "સરકારી યોજનાઓ",
    grievance: "આવશ્યકતા સબમિટ કરો",
  },
  ur: {
    greeting: "ہائے! میں ایکسس مائی انڈیا ہوں۔",
    goodbye:
      "میں درج ذیل خدمات میں آپ کی مدد کر سکتا ہوں۔ آپ درج ذیل اختیارات میں سے انتخاب کر سکتے ہیں یا صرف کوئی سوال پوچھ سکتے ہیں:",
    scheme: "سرکاری اسکیمیں",
    grievance: "ضرورت جمع کروائیں۔",
  },
  pa: {
    greeting: "ਹੈਲੋ! ਮੈਂ ਐਕਸਿਸ ਮਾਈ ਇੰਡੀਆ ਹਾਂ",
    goodbye:
      "ਮੈਂ ਹੇਠ ਲਿਖੀਆਂ ਸੇਵਾਵਾਂ ਵਿੱਚ ਤੁਹਾਡੀ ਮਦਦ ਕਰ ਸਕਦਾ/ਸਕਦੀ ਹਾਂ। ਤੁਸੀਂ ਹੇਠਾਂ ਦਿੱਤੇ ਵਿਕਲਪਾਂ ਵਿੱਚੋਂ ਚੁਣ ਸਕਦੇ ਹੋ ਜਾਂ ਕੋਈ ਸਵਾਲ ਪੁੱਛ ਸਕਦੇ ਹੋ:",
    scheme: "ਸਰਕਾਰੀ ਸਕੀਮਾਂ",
    grievance: "ਲੋੜ ਜਮ੍ਹਾਂ ਕਰੋ",
  },
  or: {
    greeting: "ହାଏ! ମୁଁ ଆକ୍ସିସ୍ ମୋ ଇଣ୍ଡିଆ |",
    goodbye:
      "ନିମ୍ନଲିଖିତ ସେବାଗୁଡ଼ିକରେ ମୁଁ ଆପଣଙ୍କୁ ସାହାଯ୍ୟ କରିପାରିବି | ଆପଣ ନିମ୍ନଲିଖିତ ବିକଳ୍ପଗୁଡ଼ିକରୁ ବାଛିପାରିବେ କିମ୍ବା କେବଳ ଯେକ question ଣସି ପ୍ରଶ୍ନ ପଚାରନ୍ତୁ:",
    scheme: "ସରକାରୀ ଯୋଜନା",
    grievance: "ଆବଶ୍ୟକତା ଦାଖଲ କରନ୍ତୁ |",
  },
  as: {
    greeting: "নমস্কাৰ! মই অক্ষ মোৰ ভাৰত",
    goodbye:
      "তলত দিয়া সেৱাসমূহৰ দ্বাৰা আপোনাক সহায় কৰিব পাৰিম। আপুনি নিম্নলিখিত বিকল্পসমূহৰ পৰা নিৰ্বাচন কৰিব পাৰে বা কেৱল যিকোনো প্ৰশ্ন সুধিব পাৰে:",
    scheme: "চৰকাৰী আঁচনি",
    grievance: "জমা দিয়াৰ প্ৰয়োজনীয়তা",
  },
};

export const placeholder = {
  en: "Please ask about any schemes",
  hi: "कृपया किसी भी योजना के बारे में पूछें",
  te: "దయచేసి ఏవైనా పథకాల గురించి అడగండి",
  ta: "ஏதேனும் திட்டங்களைப் பற்றி கேளுங்கள்",
  bn: "কোন স্কিম সম্পর্কে জিজ্ঞাসা করুন",
  kn: "ದಯವಿಟ್ಟು ಯಾವುದೇ ಯೋಜನೆಗಳ ಬಗ್ಗೆ ಕೇಳಿ",
  ml: "ഏതെങ്കിലും സ്കീമുകളെ കുറിച്ച് ചോദിക്കൂ",
  mr: "कृपया कोणत्याही योजनांबद्दल विचारा",
  gu: "કૃપા કરીને કોઈપણ યોજનાઓ વિશે પૂછો",
  ur: "براہ کرم کسی بھی اسکیم کے بارے میں پوچھیں۔",
  pa: "ਕਿਰਪਾ ਕਰਕੇ ਕਿਸੇ ਵੀ ਸਕੀਮ ਬਾਰੇ ਪੁੱਛੋ",
  or: "କ any ଣସି ଯୋଜନା ବିଷୟରେ ଦୟାକରି ପଚାରନ୍ତୁ |",
  as: "যিকোনো আঁচনিৰ বিষয়ে সুধিব",
};

export const ListenResp = {
  en: "Listen",
  hi: "सुनिए",
  te: "విను",
  ta: "கேட்டு கொள்",
  bn: "শোন",
  kn: "ಕೇಳಿ",
  ml: "ऐका",
  mr: "प्रतिसाद ऐका",
  gu: "સાંભળો",
  ur: " سنو ",
  pa: "ਸੁਣੋ",
  or: "ସୁନ",
  as: "শুন",
};

export const ShareLocation = {
  en: "Share Location",
  hi: "स्थान साझा करें",
  te: "స్థానం షేర్ చేయండి",
  ta: "இடையைப் பகிர்",
  bn: "অবস্থান ভাগ করুন",
  kn: "ಸ್ಥಾನವನ್ನು ಹಂಚಿಕೊಳ್ಳಿ",
  ml: "സ്ഥാനം പങ്കിടുക",
  mr: "स्थान सांगा",
  gu: "સ્થાન શેર કરો",
  ur: "مقام شیئر کریں",
  pa: "ਸਥਾਨ ਸਾਂਝਾ ਕਰੋ",
  or: "ଅବସ୍ଥାନ ସେୟାର କର |",
  as: "অৱস্থান শ্যার কৰক",
};

export const ShareFile = {
  en: "Share File",
  hi: "फ़ाइल साझा करें",
  te: "ఫైల్ షేర్ చేయండి",
  ta: "கேட்க பகிருங்கள்",
  bn: "ফাইল ভাগ করুন",
  kn: "ಫೈಲ್ ಹಂಚಿಕೊಳ್ಳಿ",
  ml: "ഫയല്‍ പങ്കിടുക",
  mr: "फाइल सांगा",
  gu: "ફાઇલ શેર કરો",
  ur: "فائل شیئر کریں",
  pa: "ਫਾਇਲ ਸਾਂਝਾ ਕਰੋ",
  or: "ଫାଇଲ ସେୟାର କର |",
  as: "ফাইল শ্যার কৰক",
};

export const OptionSelect = {
  en: "Please select an option",
  hi: "कृपया एक विकल्प चुनें",
  te: "దయచేసి ఒక ఐచ్ఛికం ఎంచుకోండి",
  ta: "தயவுசெய்து ஒரு விருப்பத்தைத் தேர்வு செய்க",
  bn: "দয়া করে একটি বিকল্প নির্বাচন করুন",
  kn: "ದಯವಿಟ್ಟು ಒಂದು ಆಯ್ಕೆ ಮಾಡಿ",
  ml: "ദയവായി ഒന്നു തിരഞ്ഞെടുക്കുക",
  mr: "कृपया एक पर्याय निवडा",
  gu: "કૃપયા એક વિકલ્પ પસંદ કરો",
  ur: "براہ کرم ایک اختیار منتخب کریں",
  pa: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਵਿਕਲਪ ਚੁਣੋ",
  or: "ଦୟାକରି ଏକ ପସନ୍ଦ କରନ୍ତୁ",
  as: "অনুগ্ৰহ কৰি এটি বাছনি কৰক",
};

export const Readmore = {
  en: "Read more",
  hi: "और पढ़ें",
  te: "మరింత చదవండి",
  ta: "மேலும் படிக்க",
  bn: "আরও পড়ুন",
  kn: "ಹೆಚ್ಚು ಓದು",
  ml: "കൂടുതൽ വായിക്കുക",
  mr: "वाचा आणखी",
  gu: "વધુ વાંચો",
  ur: "مزید پڑھیں",
  pa: "ਹੋਰ ਪੜ੍ਹੋ",
  or: "ଆଗକୁ ପଢନ୍ତୁ",
  as: "আৰও পঢ়ক",
};

export const Online = {
  en: "Online",
  hi: "ऑनलाइन",
  te: "ఆన్‌లైన్",
  ta: "ஆன்லைன்",
  bn: "অনলাইন",
  kn: "ಆನ್‌ಲೈನ್",
  ml: "ഓൺ‌ലൈൻ",
  mr: "ऑनलाइन",
  gu: "ઑનલાઇન",
  ur: "آن لائن",
  pa: "ਆਨਲਾਈਨ",
  or: "ଅନଲାଇନ",
  as: "অনলাইন",
};

export const AMI = {
  en: "Axis People Bot",
  hi: "ऑक्सिस पीपल बॉट",
  te: "యాక్సిస్ పీపుల్ బాట్",
  ta: "ஆசிஸ் பீபிள் பாட்",
  bn: "অ্যাক্সিস পিপল বট",
  kn: "ಆಕ್ಸಿಸ್ ಪೀಪಲ್ ಬಾಟ್",
  ml: "ആക്സിസ് പീപ്പിൾ ബോട്ട്",
  mr: "ऍक्सिस पीपल बॉट",
  gu: "ઍક્સિસ પીપલ બોટ",
  ur: "ایکسس لوگوں بوٹ",
  pa: "ਐਕਸਿਸ ਲੋਕ ਬੋਟ",
  or: "ଅକ୍ସିସ୍ ପୀପୁଲ୍ ବଟ",
  as: "এক্সিছ পীপল বট",
};

export const AxisPeople = {
  en: "Axis My India",
  hi: "एक्सिस माई इंडिया",
  te: "యాక్సిస్ మై ఇండియా",
  ta: "அச்சு என் இந்தியா",
  bn: "অক্ষ মেরা ভারত",
  kn: "ಆಕ್ಸಿಸ್ ಮೈ ಇಂಡಿಯಾ",
  ml: "ആക്സിസ് മൈ ഇന്ത്യ",
  mr: "अक्ष माझा भारत",
  gu: "એક્સિસ માય ઇન્ડિયા",
  ur: "ایکسس مائی انڈیا",
  pa: "ਐਕਸਿਸ ਮਾਈ ਇੰਡੀਆ",
  or: "ଆକ୍ସିସ୍ ମୋ ଇଣ୍ଡିଆ |",
  as: "অক্ষ মোৰ ভাৰত",
};

export const You = {
  en: "You",
  hi: "आप",
  te: "మీరు",
  ta: "நீங்கள்",
  bn: "আপনি",
  kn: "ನೀವು",
  ml: "നിങ്ങൾ",
  mr: "आपण",
  gu: "તમે",
  ur: "تم",
  pa: "ਤੁਹਾਨੂੰ",
  or: "ତୁମେ",
  as: "আপুনি",
};

export const ShowOptions = {
  en: "Show options",
  hi: "विकल्प दिखाएँ",
  te: "ఎంపికలను చూపు",
  ta: "விருப்பங்களைக் காட்டு",
  bn: "অপশন দেখান",
  kn: "ಆಯ್ಕೆಗಳನ್ನು ತೋರಿಸಿ",
  ml: "ഓപ്ഷനുകൾ കാണിക്കുക",
  mr: "पर्याय दाखवा",
  gu: "વિકલ્પો બતાવો",
  ur: "اختیارات دکھائیں۔",
  pa: "ਵਿਕਲਪ ਦਿਖਾਓ",
  or: "ବିକଳ୍ପଗୁଡିକ ଦେଖାନ୍ତୁ |",
  as: "বিকল্পসমূহ দেখুৱাওক",
};

export const AutoSuggestionsTranslation = {
  en: "Auto suggestions",
  hi: "ऑटो सुझाव",
  te: "స్వీయ సూచనలు",
  ta: "தானியங்கு பரிந்துரைகள்",
  bn: "অটো সাজেশন",
  kn: "ಸ್ವಯಂ ಸಲಹೆಗಳು",
  ml: "യാന്ത്രിക നിർദ്ദേശങ്ങൾ",
  mr: "ऑटो सूचना",
  gu: "સ્વતઃ સૂચનો",
  ur: "خودکار تجاویز",
  pa: "ਆਟੋ ਸੁਝਾਅ",
  or: "ଅଟୋ ପରାମର୍ଶ |",
  as: "অটো পৰামৰ্শ",
};

// export const Hospitals = {
//   en: "Recommended Schemes",
//   hi: "अनुशंसित योजनाएँ",
//   te: "సిఫార్సు చేసిన పథకాలు",
//   ta: "பரிந்துரைக்கப்பட்ட திட்டங்கள்",
//   bn: "প্রস্তাবিত স্কিম",
//   kn: "ಶಿಫಾರಸು ಮಾಡಲಾದ ಯೋಜನೆಗಳು",
//   ml: "ശുപാർശ ചെയ്യുന്ന സ്കീമുകൾ",
//   mr: "शिफारस केलेल्या योजना",
//   gu: "ભલામણ કરેલ યોજનાઓ",
//   ur: "تجویز کردہ اسکیمیں",
//   pa: "ਸਿਫ਼ਾਰਿਸ਼ ਕੀਤੀਆਂ ਸਕੀਮਾਂ",
//   or: "ସୁପାରିଶ କରାଯାଇଥିବା ଯୋଜନାଗୁଡିକ",
//   as: "পৰামৰ্শ দিয়া আঁচনিসমূহ",
// };

export const Hospitals = {
  en: "Ayushman Bharat Hospitals",
  hi: "आयुष्मान भारत अस्पताल",
  te: "ఆయుష్మాన్ భారత్ హాస్పిటల్స్",
  ta: "ஆயுஷ்மான் பாரத் மருத்துவமனைகள்",
  bn: "আয়ুষ্মান ভারত হাসপাতাল",
  kn: "ಆಯುಷ್ಮಾನ್ ಭಾರತ್ ಆಸ್ಪತ್ರೆಗಳು",
  ml: "ആയുഷ്മാൻ ഭാരത് ആശുപത്രികൾ",
  mr: "आयुष्मान भारत रुग्णालये",
  gu: "આયુષ્માન ભારત હોસ્પિટલ્સ",
  ur: "آیوشمان بھارت ہسپتال",
  pa: "ਆਯੁਸ਼ਮਾਨ ਭਾਰਤ ਹਸਪਤਾਲ",
  or: "ଆୟୁଷ୍ମାନ ଭାରତ ଡାକ୍ତରଖାନା |",
  as: "আয়ুষ্মান ভাৰত হাস্পতাল",
};

export const SearchOptions = {
  en: "Search options",
  hi: "खोज विकल्प",
  te: "శోధన ఐచుక ఎంపికలు",
  ta: "தேடல் விருப்பங்கள்",
  bn: "অনুসন্ধান বিকল্প",
  kn: "ಹುಡುಕಾಟ ಆಯ್ಕೆಗಳು",
  ml: "തിരയൽ ഐച്ഛികങ്ങൾ",
  mr: "शोध विकल्प",
  gu: "શોધ વિકલ્પો",
  ur: "تلاش کے اختیارات",
  pa: "ਖੋਜ ਚੋਣਾਂ",
  or: "ଖୋଜ ସଂକଲନ",
  as: "অনুসন্ধান বিকল্পসমূহ",
};

export const OpentionsNotFound = {
  en: "Options Not Found",
  hi: "विकल्प नहीं मिला",
  te: "ఎంపికలు కనబడలేదు",
  ta: "விருப்பங்கள் கிடைக்கவில்லை",
  bn: "বিকল্প পাওয়া যায়নি",
  kn: "ಆಯ್ಕೆಗಳು ಸಿಗಲಿಲ್ಲ",
  ml: "ഐച്ഛികങ്ങൾ കിട്ടിയില്ല",
  mr: "विकल्पे नसताना",
  gu: "વિકલ્પો મળ્યા નથી",
  ur: "اختیارات دستیاب نہیں ہیں",
  pa: "ਚੋਣਾਂ ਨਹੀਂ ਮਿਲੀਆਂ",
  or: "ବିକଳ୍ପ ନାଁ ପାଇନଥିଲା",
  as: "বিকল্পসমূহ পোৱা নাযায়",
};

export const Hospital = {
  en: ["Hospital Details", "Name", "Distance", "Contact no", "Address"],
  hi: ["अस्पताल विवरण", "नाम", "दूरी", "संपर्क नंबर", "पता"],
  te: ["ఆసుపత్రి వివరాలు", "పేరు", "దూరం", "సంప్రదంచాల్సిన నెం", "చిరునామా"],
  ta: ["மருத்துவமனை விவரங்கள்", "பெயர்", "தூரம்", "தொடர்பு எண்", "முகவரி"],
  bn: ["হাসপাতালের বিবরণ", "নাম", "দূরত্ব", "যোগাযোগের নম্বর", "ঠিকানা"],
  kn: ["ಆಸ್ಪತ್ರೆಯ ವಿವರಗಳು", "ಹೆಸರು", "ದೂರ", "ಸಂಪರ್ಕ ಸಂಖ್ಯೆ", "ವಿಳಾಸ"],
  ml: ["ആശുപത്രി വിശദാംശങ്ങൾ", "പേര്", "ദൂരം", "ബന്ധപ്പെടേണ്ട നമ്പർ", "വിലാസം"],
  mr: ["हॉस्पिटल तपशील", "नाव", "अंतर", "संपर्क क्र", "पत्ता"],
  gu: ["હોસ્પિટલ વિગતો", "નામ", "અંતર", "સંપર્ક નં", "સરનામું"],
  ur: ["ہسپتال کی تفصیلات", "نام", "فاصلے", "رابطہ نمبر", "پتہ"],
  pa: ["ਹਸਪਤਾਲ ਦੇ ਵੇਰਵੇ", "ਨਾਮ", "ਦੂਰੀ", "ਸੰਪਰਕ ਨੰ", "ਪਤਾ"],
  or: ["ହସ୍ପିଟାଲ୍ ବିବରଣୀ", "ନାମ", "ଦୂରତା |", "ଯୋଗାଯୋଗ ନଂ", "ଠିକଣା"],
  as: ["চিকিৎসালয়ৰ বিৱৰণ", "নাম", "দূৰত্ব", "যোগাযোগ নং", "ঠিকনা"],
};

export const HospitalHeader = {
  en: "Please click to view hospital details",
  hi: "कृपया अस्पताल का विवरण देखने के लिए क्लिक करें",
  te: "ఆసుపత్రి వివరాలను చూడటానికి దయచేసి క్లిక్ చేయండి",
  ta: "மருத்துவமனை விவரங்களைப் பார்க்க கிளிக் செய்யவும்",
  bn: "হাসপাতালের বিস্তারিত দেখতে ক্লিক করুন",
  kn: "ಆಸ್ಪತ್ರೆಯ ವಿವರಗಳನ್ನು ವೀಕ್ಷಿಸಲು ದಯವಿಟ್ಟು ಕ್ಲಿಕ್ ಮಾಡಿ",
  ml: "ആശുപത്രി വിശദാംശങ്ങൾ കാണാൻ ദയവായി ക്ലിക്ക് ചെയ്യുക",
  mr: "कृपया हॉस्पिटल तपशील पाहण्यासाठी क्लिक करा",
  gu: "હોસ્પિટલની વિગતો જોવા માટે કૃપા કરીને ક્લિક કરો",
  ur: "ہسپتال کی تفصیلات دیکھنے کے لیے براہ کرم کلک کریں۔",
  pa: "ਕਿਰਪਾ ਕਰਕੇ ਹਸਪਤਾਲ ਦੇ ਵੇਰਵੇ ਦੇਖਣ ਲਈ ਕਲਿੱਕ ਕਰੋ",
  or: "ହସ୍ପିଟାଲର ବିବରଣୀ ଦେଖିବାକୁ ଦୟାକରି କ୍ଲିକ୍ କରନ୍ତୁ |",
  as: "চিকিৎসালয়ৰ সবিশেষ চাবলৈ ক্লিক কৰক",
};

export const ListeningTrans = {
  en: "Listening",
  hi: "सुनना",
  te: "వినుటకు",
  ta: "கேட்டல்",
  bn: "শোনা",
  kn: "ಕೇಳುತ್ತಿದ್ದೇನೆ",
  ml: "കേട്ടല്",
  mr: "ऐकणे",
  gu: "સાંભળવું",
  ur: "سننا",
  pa: "ਸੁਣਨਾ",
  or: "ସୁନିବା",
  as: "শোনা",
};

export const audio = {
  en: "https://storage.googleapis.com/ami-tts-storage/af932d4d-c661-4e6d-8842-1ae3d65febd3.wav",
  hi: "https://storage.googleapis.com/ami-tts-storage/35f33e5e-8826-4091-894b-02669e755d08.wav",
  te: "https://storage.googleapis.com/ami-tts-storage/34c60786-0e6a-42b4-a095-1804977d9a6f.wav",
  ta: "https://storage.googleapis.com/ami-tts-storage/399bcfec-209f-497b-8875-02ce2cc55568.wav",
  bn: "https://storage.googleapis.com/ami-tts-storage/ae1373ab-88aa-48ab-ab04-244ffb3552e0.wav",
  kn: "https://storage.googleapis.com/ami-tts-storage/95e6e2e3-4bbd-4a53-aa37-d5448228022f.wav",
  ml: "https://storage.googleapis.com/ami-tts-storage/a16f1cea-4941-4fad-ab63-1a4d6f3f92e4.wav",
  mr: "https://storage.googleapis.com/ami-tts-storage/c3454e64-3dfa-412f-9968-5edddbfcdecc.wav",
  gu: "https://storage.googleapis.com/ami-tts-storage/abdecd18-d7ef-4845-8e51-7e7553c1ae21.wav",
  ur: "https://storage.googleapis.com/ami-tts-storage/e9e57ea9-0b65-40f5-bac0-5a0a5f030ef5.wav",
  pa: "https://storage.googleapis.com/ami-tts-storage/65fde94e-27c9-416e-b154-f7d623248988.wav",
  or: "https://storage.googleapis.com/ami-tts-storage/11ef9dd8-eda0-4413-bc7f-513bf3d940f7.wav",
  as: "https://storage.googleapis.com/ami-tts-storage/5f1aedb2-b66c-47bd-a6cc-da631e47cec4.wav",
};

export const shortAudio = {
  en: "https://storage.googleapis.com/ami-tts-storage/b5bdc98d-fb73-4151-b64b-0e3cdd3eb6ec.wav",
  hi: "https://storage.googleapis.com/ami-tts-storage/c91060c2-1014-4883-bb79-169ceb20ea10.wav",
  te: "https://storage.googleapis.com/ami-tts-storage/c152839c-182b-4155-9ddb-7012bf60c215.wav",
  ta: "https://storage.googleapis.com/ami-tts-storage/8da782e6-e305-459a-8ae1-e686776dc115.wav",
  bn: "https://storage.googleapis.com/ami-tts-storage/d704bd2e-c418-4004-b8c1-c08a92c6c72e.wav",
  kn: "https://storage.googleapis.com/ami-tts-storage/1d1b36ea-246c-4788-a1ec-e6878335b80b.wav",
  ml: "https://storage.googleapis.com/ami-tts-storage/6cb91709-2d08-4812-8dd3-c66c98936587.wav",
  mr: "https://storage.googleapis.com/ami-tts-storage/96b5b995-10a0-47c8-b7c2-e730e4017647.wav",
  gu: "https://storage.googleapis.com/ami-tts-storage/5893d594-a773-44c9-ab5e-817d917b8a34.wav",
  ur: "https://storage.googleapis.com/ami-tts-storage/b0bd3293-6002-41da-80dc-8c3536574330.wav",
  pa: "https://storage.googleapis.com/ami-tts-storage/d11e8440-63ea-4973-a138-361fa877138d.wav",
  or: "https://storage.googleapis.com/ami-tts-storage/6185d501-df26-4bbb-bb88-f493a8687a5d.wav",
  as: "https://storage.googleapis.com/ami-tts-storage/7faa9e5f-482b-4106-b4ac-b4b98dd7d1f3.wav",
};

export const skip = {
  en: "Skip",
  hi: "छोडना",
  te: "దాటవేయి",
  ta: "தவிர்க்கவும்",
  bn: "এড়িয়ে যান",
  kn: "ಬಿಟ್ಟುಬಿಡಿ",
  ml: "ഒഴിവാക്കുക",
  mr: "वगळा",
  gu: "છોડો",
  ur: "چھوڑ دو",
  pa: "ਛੱਡੋ",
  or: "ଛାଡିଦିଅ |",
  as: "বাদ দিয়া",
};

export const Feedback = {
  en: "Please provide your valuable feedback",
  hi: "कृपया अपनी बहुमूल्य प्रतिक्रिया दें",
  te: "దయచేసి మీ విలువైన అభిప్రాయాన్ని అందించండి",
  ta: "உங்கள் மதிப்புமிக்க கருத்தை வழங்கவும்",
  bn: "আপনার মূল্যবান মতামত প্রদান করুন",
  kn: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಅಮೂಲ್ಯವಾದ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಒದಗಿಸಿ",
  ml: "ദയവായി നിങ്ങളുടെ വിലയേറിയ ഫീഡ്ബാക്ക് നൽകുക",
  mr: "कृपया आपला मौल्यवान अभिप्राय द्या",
  gu: "કૃપા કરીને તમારો મૂલ્યવાન પ્રતિભાવ આપો",
  ur: "اپنی قیمتی آراء سے نوازیں۔",
  pa: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਕੀਮਤੀ ਫੀਡਬੈਕ ਦਿਓ",
  or: "ଦୟାକରି ଆପଣଙ୍କର ମୂଲ୍ୟବାନ ମତାମତ ପ୍ରଦାନ କରନ୍ତୁ |",
  as: "অনুগ্ৰহ কৰি আপোনাৰ বহুমূলীয়া মতামত আগবঢ়াওক",
};

export const Save = {
  en: "Save",
  hi: "सेव करें",
  te: "సేవ్",
  ta: "சேமிக்க",
  bn: "সংরক্ষণ",
  kn: "ಉಳಿಸಿ",
  ml: "രക്ഷിക്കും",
  mr: "जतन करा",
  gu: "સાચવો",
  ur: "محفوظ کریں",
  pa: "ਬਚਾਓ",
  or: "ସଞ୍ଚୟ କରନ୍ତୁ |",
  as: "সঞ্চয় কৰা",
};

export const Cancel = {
  en: "Cancel",
  hi: "कैंसिल करें",
  te: "రద్దు చేయండి",
  ta: "ரத்து செய்",
  bn: "বাতিল",
  kn: "ರದ್ದುಮಾಡು",
  ml: "റദ്ദാക്കുക",
  mr: "रद्द करा",
  gu: "રદ કરો",
  ur: "منسوخ",
  pa: "ਰੱਦ ਕਰੋ",
  or: "ବାତିଲ୍ କରନ୍ତୁ |",
  as: "বাতিল কৰক",
};

export const FeedBackInput = {
  en: "Type here...",
  hi: "यहाँ टाइप करें...",
  te: "ఇక్కడ టైపు చేయండి...",
  ta: "இங்கே தட்டச்சு செய்யவும்...",
  bn: "এখানে লিখুন...",
  kn: "ಇಲ್ಲಿ ಬರೆಯಿರಿ...",
  ml: "ഇവിടെ ടൈപ്പ് ചെയ്യു...",
  mr: "येथे लिहा...",
  gu: "અહી લખો...",
  ur: "یہاں ٹائپ کریں...",
  pa: "ਇੱਥੇ ਟਾਈਪ ਕਰੋ...",
  or: "ଏଠାରେ ଟାଇପ୍ କରନ୍ତୁ ...",
  as: "ইয়াত টাইপ কৰক...",
};

export const LanguageHeader = {
  en: "Please select a language",
  hi: "कृपया एक भाषा चुनें",
  te: "దయచేసి ఒక భాషను ఎంచుకోండి",
  ta: "ஒரு மொழியை தேர்ந்தெடுக்கவும்",
  bn: "দয়া করে একটি ভাষা নির্বাচন করুন",
  kn: "ದಯವಿಟ್ಟು ಒಂದು ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  ml: "ഒരു ഭാഷ തിരഞ്ഞെടുക്കുക",
  mr: "कृपया एक भाषा निवडा",
  gu: "કૃપા કરીને એક ભાષું પસંદ કરો",
  ur: "براہ کرم ایک زبان منتخب کریں",
  pa: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਭਾਸ਼ਾ ਚੁਣੋ",
  or: "ଦୟାକରି ଏକ ଭାଷା ଚୟନ କରନ୍ତୁ",
  as: "অনুগ্ৰহ কৰি এটি ভাষা বাচন কৰক",
};



export const fileLimt = {
  en: "The uploaded file is excessively large. Upload files no larger than 5MB",
  hi: "अपलोड किया गया फ़ाइल बहुत बड़ी है। 5MB से बड़ी फ़ाइलें अपलोड न करें",
  te: "అప్లోడ్ చేసిన ఫైలు అతిపెద్దదిగా ఉంది. 5MB కంటే పెద్ద ఫైళ్ళను అప్లోడ్ చేయాక ఉండండి",
  ta: "பதிவேற்றிய கோப்பு மிகவும் பெரியது. 5MB க்கு அதிகமான கோப்புகளை பதிவேற்றவும் இல்லை",
  bn: "আপলোড করা ফাইলটি অত্যন্ত বড়। 5MB এর বড় ফাইল আপলোড করবেন না",
  kn: "ಅಪ್‌ಲೋಡ್ ಮಾಡಿದ ಕಡಿಮೆಕಾರಣ ಅದು ಅತಿ ದೊಡ್ಡದಾಗಿದೆ. 5MB ಕಿಂಚಿತ್ತು ದೊಡ್ಡದಾದ ಫೈಲ್‌ಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
  ml: "അപ്‌ലോഡുചെയ്ത ഫയൽ അതിനുള്ള വലുപ്പമേറിയതാണ്. 5MB കുറഞ്ഞത് അപ്‌ലോഡുചെയ്യുക",
  mr: "अपलोड केलेली फाइल अत्यंत मोठी आहे. 5MB पेक्षा जास्त फाइलें अपलोड करू नका",
  gu: "અપલોડ કરેલી ફાઇલ ખૂબ મોટી છે. 5MB થી વધુ મોટી ફાઇલો અપલોડ કરવાનો નહીં",
  ur: "اپ لوڈ کردہ فائل بہت زیادہ بڑی ہے۔ 5MB سے زیادہ بڑی فائلیں اپ لوڈ نہ کریں",
  pa: "اپ لوڈ کیتی گئی فائل بہت زیادہ بڑی ہے۔ 5MB توں وڈیاں فائلیں اپ لوڈ نہ کریں",
  or: "ଆପଲୋଡ୍ କରାଯାଇଛି ଫାଇଲଟି ଅତିସରଳ ଅଛି। 5MB ରେ ବଡ଼ ଫାଇଲ୍‌ ଆପଲୋଡ୍ କରନ୍ତୁ ନାହିଁ",
  as: "আপলোড কৰা ফাইলটি অত্যধিক বড়। 5MB ত বৰণি ফাইল আপলোড কৰক নহয়",
};



export const fileLimtWar = {
  en: "The uploaded file must not exceed 5MB in size.",
  hi: "अपलोड किया गया फ़ाइल 5MB से अधिक आकार में नहीं होना चाहिए।",
  te: "అప్లోడ్ చేసిన ఫైలు 5MB ను మీరుకోకాలి.",
  ta: "பதிவேற்றிய கோப்பு 5MB க்கும் அதிகமாக இருக்கக்கூடாது.",
  bn: "আপলোড করা ফাইলটির আকার 5MB এর বেশি হতে পারবে না।",
  kn: "ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ಹೊರಡಿಸಲಾದ ಕಡಿಮೆಯಾಗಿರುವ ಕೊಡುಗೆ 5MB ಗಿಗಿಲಿನವರೆಗೆ ಇರಬಾರದು.",
  ml: "അപ്‌ലോഡുചെയ്ത ഫയൽ 5MB കുറഞ്ഞതായിരിക്കണമെങ്കിൽ അതിൽ കൂടുതൽ ആകാം.",
  mr: "अपलोड केलेली फाइल 5MB पेक्षा अधिक असू नये.",
  gu: "અપલોડ કરેલી ફાઇલનો આકાર 5MB થી વધુ ન થવો.",
  ur: "اپ لوڈ کردہ فائل کا سائز 5MB سے زیادہ نہیں ہونا چاہئے۔",
  pa: "اپ لوڈ کردی گئی فائل 5MB توں زیادہ ہونہ چاہئی۔",
  or: "ଆପଲୋଡ୍ କରିଥିବା ଫାଇଲ 5MB ର ଅଧିକ ନାହିଁ ହେବା।",
  as: "আপলোড কৰা ফাইলৰ আকাৰ 5MB ত অধিক নাহয়।",
};