import axios from "axios";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const userToken = uuidv4();
sessionStorage.setItem("userToken", userToken);

const token = sessionStorage.getItem("userToken") || uuidv4();

const searchParams = new URLSearchParams(document.location.search);
const token_ = searchParams.get("token");
const header = {
  token: `Bearer ${token_}`,
};

(async () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      axios
        .post("/amiAPI/bot/saveUserLoc", {
          loc: `${position.coords.latitude},${position.coords.longitude}`,
          userToken,
        })
        .then((res) => {});
    });
  }
})();

export function Api(apiBody: any, lang: any, inputType: any, callback: any) {
  const url = `/amiAPI/bot/sendQuery/${lang}`;

  const body = {
    query: apiBody,
    userToken: token,
    inputType: inputType,
    source: "bot",
  };

  
    axios
    .post(url, body, { headers: header })
    .then((response) => {
      if (response && response.data !== undefined) {
        callback(response.data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function FileUpload(apiBody: any, lang: any, callback: any) {
  const url = `/amiAPI/bot/sendQuery/${lang}`;
  const body = new FormData();
  body.append("userToken", token);
  body.append("file", apiBody);
  body.append("source", "bot");
  axios
    .post(url, body, { headers: header })
    .then((response) => {
      if (response && response.data !== undefined) {
        callback(response.data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function RecommendSchemesApi(lang: any, callback: any) {
  const url = `/amiAPI/bot/recommendSchemes/${lang}`;

  const body = {
    userToken: token,
    source: "bot",
  };

  axios
    .post(url, body, { headers: header })
    .then((response) => {
      if (response && response.data !== undefined) {
        callback(response.data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function FeedbackApi(
  answerId: any,
  feedback: boolean,
  feedbackTxt: any
  // callback: any
) {
  const url = `/amiAPI/bot/feedback`;
  const body = {
    answerId: answerId,
    feedback: {
      like: feedback,
      comment: feedbackTxt,
    },
  };

  axios
    .post(url, body, { headers: header })
    .then((response) => {
      if (response && response.data !== undefined) {
        // callback(response.data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// const useRecommendSchemesApi = (lang) => {
//   const [recommendSchemesdata, setRecommendSchemesData] = useState(null);
//   const [recommendSchemeserror, setRecommendSchemesError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       setRecommendSchemesError(null);

//       try {
//         const url = `/amiAPI/bot/recommendSchemes/${lang}`;
//         const body = {
//           userToken: token,
//           source: "bot",
//         };

//         const response = await axios.post(url, body, { headers: header }); // Make sure 'header' is defined

//         if (response && response.data !== undefined) {
//           setRecommendSchemesData(response.data);
//         }
//       } catch (error) {
//         setRecommendSchemesData(error);
//       }
//     };

//     fetchData();
//   }, [lang]);

//   return { recommendSchemesdata, recommendSchemeserror };
// };

// const useSendQueryApi = () => {
//   const [sendQuerydata, setSendQueryData] = useState(null);
//   const [sendQueryError, setSendQueryError] = useState(null);

//   const fetchData = async (apiBody, lang, inputType) => {
//     setSendQueryError(null);

//     try {
//       const url = `/amiAPI/bot/sendQuery/${lang}`;
//       const body = {
//         query: apiBody,
//         userToken: token, // Replace 'token' with your actual token
//         inputType: inputType,
//         source: "bot",
//       };
//       console.log(body);
//       const response = await axios.post(url, body, { headers: header }); // Make sure 'header' is defined

//       if (response && response.data !== undefined) {
//         console.log(response.data);
//         setSendQueryData(response.data);
//       }
//     } catch (error) {
//       setSendQueryData(error);
//     }
//   };

//   return { sendQuerydata, sendQueryError, fetchData };
// };

// export  { useRecommendSchemesApi, useSendQueryApi };

window.addEventListener("beforeunload", () => {
  sessionStorage.clear();
});
