import React, { useEffect, useState } from "react";
import { AutoSuggestionsTranslation } from "../../Translation";
import moment from "moment";
import { RecommendSchemesApi } from "../../Api";
export default function AutoSuggestions(props: any) {
  const {
    input,
    setInput,
    selectedLanguage,
    apiCall,
    autoPlay,
    setLoading,
    setMessages,
    autoInput,
    autoSuggScheme
  } = props;
  const [autoSuggestionsView, setAutoSuggestionsView] = useState(false);


  const autoSelections =
    input &&
    autoSuggScheme.length > 0 &&
    input.length > 0 &&
    autoSuggScheme.filter((value: any) =>
      value.label.toLowerCase().includes(input.toLowerCase())
    );
  const handleAutoSuggestions = (data: { value: string; label: string }) => {
    setInput("");
    setMessages((prevChats: any) => [
      ...prevChats,
      {
        text: data.label,
        sender: "user",
        time: moment().format("h:mm A"),
      },
    ]);

    setLoading(true);
    apiCall(data.value, autoPlay, "text");
  };

  // useEffect(() => {
   
  // }, [selectedLanguage]);

  useEffect(() => {
    if (
      autoInput &&
      input !== undefined &&
      input.length > 0 &&
      autoSelections.length > 0
    ) {
      setAutoSuggestionsView(true);
    } else {
      setAutoSuggestionsView(false);
    }
  }, [input, autoSelections]);

  return (
    <div>
      {autoSuggestionsView && (
        <div className="autoSuggesstionsContainer">
          <div style={{ overflowY: "hidden", height: "100%", bottom: "40px" }}>
            <p className="optionHeader">
              {(AutoSuggestionsTranslation as any)[selectedLanguage]}
            </p>

            {autoSelections &&
              autoSelections.length > 0 &&
              autoSelections.slice(0, 5).map((data: any, index: number) => (
                <div key={index}>
                  <p
                    className="autoSuggestionText"
                    onClick={() => {
                      handleAutoSuggestions(data);
                    }}
                  >
                    {data.label}
                  </p>
                  <hr />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
