import React from "react";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
export const SendMsg = (
  <SendOutlinedIcon
    sx={{ color: "black", fontSize: "28px !important", padding: "1px" }}
  />
);

export const Mic = (
  <KeyboardVoiceOutlinedIcon
    sx={{
      color: "#303841",
      fontSize: "30px !important",
    }}
  />
);

export const VoiceMic = (
  <KeyboardVoiceIcon
    sx={{
      zIndex: 100,
      color: "#91278F",
      fontSize: "55px !important",
    }}
  />
);

export const Play = (
  <PlayCircleIcon
    sx={{
      color: "#a91079",
      fontSize: "25px !important",
      marginTop: "-5px",
      paddingRight: "5px",
    }}
  />
);

export const Pause = (
  <PauseCircleIcon
    sx={{
      color: "#e9c7f3",
      fontSize: "px !important",
      marginTop: "-5px",
      paddingRight: "5px",
    }}
  />
);
