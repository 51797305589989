import { LoadingContainer, Logo, LoadingAnimation } from "./Styles";

export default function Loading() {
  return (
    <LoadingContainer className={"LoadingContainer"}>
      <Logo className="Logo " src="a_logo.png" alt="icon" />
      <LoadingAnimation className={"LoadingAnimation"}> </LoadingAnimation>
    </LoadingContainer>
  );
}
