import React, { useState, useEffect, useRef } from "react";
import "./Styles.css";
import moment from "moment";
import { Api, FileUpload, RecommendSchemesApi } from "../../Api";
import Voice from "../Voice/Voice";
import {
  Translation,
  Hospitals,
  OptionSelect,
  HospitalHeader,
  audio,
  shortAudio,
  ListeningTrans,
  fileLimt,
  fileLimtWar,
} from "../../Translation/index";
import MicAndTextfield from "../MicAndTextfield/MicAndTextfield";
import Messages from "./Messages";
import LanguageOptions from "./LanguageOptions";
import SchemesOptions from "./SchemesOptions";
import AutoSuggestions from "./AutoSuggestions";
import HospitalOptions from "./HospitalOptions";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import useRecorder from "./recorder/useRecorder";
import { schemeData } from "../../Utils/Data";
import Fuse from "fuse.js";
const searchParams = new URLSearchParams(document.location.search);
const language = searchParams.get("locale") ? searchParams.get("locale") : "en";

const ChatContent = (props: any) => {
  const { Lang } = props;
  const chatMessageRef = useRef<HTMLDivElement | null>(null);
  const [hideInput, setHideInput] = useState(false);
  const [autoPlay, setAutoPlay] = useState<boolean>(false);
  const [optionList, setOptionList] = useState<any>([]);
  const [input, setInput] = useState<any>("");
  const [selectedLanguage, setSelectedLanguage] = useState<string>(language);
  const audioElementRef = useRef(null);
  const [currentAudioUrl, setCurrentAudioUrl] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [voiceRecognition, setVoiceRecognition] = useState<boolean>(false);
  const [inputField, setInputField] = useState<boolean>(true);
  const [fileUpload, setFileUpload] = useState(false);  
  const [location, setLocation] = useState(false);
  const [micBtn, setMicBtn] = useState(true);
  const [sendBtn, setSendBtn] = useState(false);
  const [active, setActive] = useState(false);
  const [languageOptions, setLanguageOptions] = useState(false);
  const translations = JSON.parse(JSON.stringify(Translation));
  const [isEnded, setIsEnded] = useState(false);
  const [HospitalView, setHospitalView] = useState(false);
  const [HospitalData, setHospitalData] = useState({});
  const [backOptions, setBackOptions] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [transcriptTxt, setTranscriptTxt] = useState<any>("");
  const [renderMic, setRenderMic] = useState(true);
  const [autoInput, setAutoInput] = useState(true);
  const [autoSuggScheme, setAutoSuggScheme] = useState([]);

  const recorder = useRecorder((blob) => {
    apiCall(blob, true, "voice");
    setLoading(true);
  });

  const [optionHeader, SetOptionsHeader] = useState(
    (OptionSelect as any)[selectedLanguage]
  );

  const { transcript, listening } = useSpeechRecognition();

  const schemeOptions = [
    {
      val: translations[selectedLanguage].scheme,
      id: 1,
    },
    {
      val: translations[selectedLanguage].grievance,
      id: 2,
    },
    {
      val: (Hospitals as any)[selectedLanguage],
      id: 1,
    },
  ];
  // const { sendQuerydata, sendQueryError, fetchData } = useSendQueryApi();
  const [messages, setMessages] = useState<any>([]);
  const isIOS = () => {
    const platform = window.navigator.platform.toLowerCase();

    return (
      (platform.includes("mac") || platform.startsWith("ip")) &&
      selectedLanguage != "en" &&
      selectedLanguage != "hi"
    );
  };

  const requestPermission = async () => {
    try {
      startRecognition();
      isIOS() && recorder.startRecording();
    } catch (error) {
      console.error("Permission denied:", error);
      alert("Error: " + error);
    }
  };
  const startRecognition = () => {
    playAudio(
      "https://coroverbackendstorage.blob.core.windows.net/chatbot-audio-bucket/start.mp3"
    );
    pauseAudio();
    setVoiceRecognition(true);
    SpeechRecognition.startListening({
      continuous: false,
      language: isIOS() ? `en-US` : `${selectedLanguage}-IN`,
    });
    isIOS() && recorder.startRecording();
  };

  const stopRecognition = () => {
    SpeechRecognition.stopListening();
    isIOS() && recorder.saveRecording();
  };

  const handleSendMessage = () => {
    if (input.trim() !== undefined && input.trim() !== "") {
      setMessages([
        ...messages,
        { text: input, sender: "user", time: moment().format("h:mm A") },
      ]);

      apiCall(input, false, "text");
      setLoading(true);
      setInput("");
    }
  };

  //play audio
  const playAudio = (audioUrl: string) => {
    if (!audioUrl) return;

    const audioElement: HTMLAudioElement | null = audioElementRef.current;

    if (audioElement) {
      audioElement.src = audioUrl;
      audioElement.load();

      audioElement.addEventListener("canplaythrough", () => {
        isIOS() && (audioElement.volume = 0.5);
        audioElement.play();
        setCurrentAudioUrl(audioUrl);
        setIsPlaying(true);
      });

      audioElement.addEventListener("ended", () => {
        setIsPlaying(false);
      });
    }
  };

  // Pause audio
  const pauseAudio = () => {
    const audioElement: any = audioElementRef.current;
    audioElement!==null&&audioElement.pause();
    setIsPlaying(false);
  };

  const apiCall = async (
    input: string,
    autoplay_: boolean,
    inputType: string
  ) => {
    setHideInput(false);
    setIsEnded(false);
    setInputField(true);
    setBackOptions(false);
    setAutoPlay(autoplay_);
    // useSendQueryApi(input, selectedLanguage, inputType);
    await Api(input, selectedLanguage, inputType, (res: any) => {
      if (res.answer.query) {
        setMessages((prevChats: any) => [
          ...prevChats,
          {
            text: res.answer.query,
            sender: "user",
            time: moment().format("h:mm A"),
          },
        ]);
        setVoiceRecognition(false);
      }

      if (res.answer.option) {
        setOptionList(res.answer.option);
        setHideInput(true);
        setInputField(false);
        setAutoPlay(false);
      }
      if (res.answer.response === "Please describe your concern") {
        setAutoInput(false);
      } else {
        setAutoInput(true);
      }
      setLoading(false);
      // setCount(count + 1);
      setMessages((prevChats: any) => [
        ...prevChats,
        {
          text: res.answer.response,
          sender: "bot",
          audio: res.answer.audio,
          options: res.answer.option,
          knowMore: res.answer.knowMore,
          end: res.answer.end,
          time: moment().format("h:mm A"),
          answerId: res.answer.answerId,
        },
      ]);

      playAudio(res.answer.audio);

      if (
        res.answer.action &&
        (res.answer.action.includes("=grevLoc=") ||
          res.answer.action.includes("=hospLoc="))
      ) {
        setLocation(true);
        setInputField(false);
        setAutoPlay(false);
      }
      if (res.answer.action && res.answer.action.includes("=grevFile=")) {
        setFileUpload(true);
        setInputField(false);
        setAutoPlay(false);

        setMessages((prevChats: any) => [
          ...prevChats,
          {
            text: fileLimtWar[language],
            sender: "user",
            time: moment().format("h:mm A"),
          },
        ]);
      }

      if (res.answer.end) {
        setIsEnded(true);
      }

      if (res.answer && res.answer.hospital) {
        SetOptionsHeader((HospitalHeader as any)[selectedLanguage]);
      } else {
        SetOptionsHeader((OptionSelect as any)[selectedLanguage]);
      }
    });
  };

  const handleHospitalOption = (data: any) => {
    setHideInput(false);
    setInputField(false);
    setBackOptions(true);
    setHospitalView(true);
    setSearchTerm("");
    setHospitalData(data);
  };

  const handleOptionClick = (value: any) => {
    if (value.value.includes("hospital")) {
      handleHospitalOption(value);
    } else {
      setMessages((prevChats: any) => [
        ...prevChats,
        {
          text: value.label,
          sender: "user",
          time: moment().format("h:mm A"),
        },
      ]);
      apiCall(value.value, autoPlay, "text");
      setLoading(true);
    }
  };

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];

    if (event.target.files[0].size / (1024 * 1024) < 5) {
      setFileUpload(false);
      setInputField(true);
      setAutoPlay(false);
      setBackOptions(false);

      setMessages((prevChats: any) => [
        ...prevChats,
        {
          text: file.name,
          sender: "user",
          time: moment().format("h:mm A"),
        },
      ]);

      FileUpload(file, selectedLanguage, (res: any) => {
        playAudio(res.answer.audio);
        setMessages((prevChats: any) => [
          ...prevChats,
          {
            text: res.answer.response,
            sender: "bot",
            audio: res.answer.audio,
            options: res.answer.option,
            time: moment().format("h:mm A"),
          },
        ]);
      });
    } else {
      setFileUpload(false);
      setInputField(true);
      setAutoPlay(false);
      setBackOptions(false);

      setMessages((prevChats: any) => [
        ...prevChats,
        {
          text: fileLimt[language],
          sender: "user",
          time: moment().format("h:mm A"),
        },
      ]);
    }
  };

  // Geolocation

  function getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setInput(`${latitude},${longitude}`);
          setLocation(false);
          setInputField(true);
          // Do something with the latitude and longitude
        },
        (error) => {
          console.error("Error getting user location:", error);
          setLocation(false);
          setInputField(true);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setLocation(false);
      setInputField(true);
    }
  }
  const handleLanguageChange = (val: any) => {
    setLanguageOptions(false);
    setSelectedLanguage(val);
  };

  const handleSchemes = (val: any) => {
    setMessages((prevChats: any) => [
      ...prevChats,
      {
        text: val.val,
        sender: "user",
        time: moment().format("h:mm A"),
      },
    ]);

    apiCall(val.val, autoPlay, "text");

    setLoading(true);
  };

  const resetBot = () => {
    setMessages((prevChats: any) => [
      ...prevChats,

      {
        sender: "bot",
        text: `${translations[selectedLanguage].goodbye}`,
        content: (
          <>
            {schemeOptions &&
              schemeOptions !== undefined &&
              schemeOptions.map((val: any, index: any) => (
                <span key={index}>
                  <p className="schemesList" onClick={() => handleSchemes(val)}>
                    {val.val}
                  </p>
                </span>
              ))}
          </>
        ),
        audio: shortAudio[selectedLanguage],
        time: moment().format("h:mm A"),
      },
    ]);
  };

  const searchInData = (searchTerm) => {
    if (autoSuggScheme === undefined || autoSuggScheme.length === 0) return [];
    const options = {
      keys: ["label"],
      threshold: 0.3,
    };
    const fuse = new Fuse(autoSuggScheme, options);
    const result = fuse.search(searchTerm);
    if (result.length > 0) return result[0].item.value;
    else return "";
  };
  /******************* Life Cycle ******************/

  useEffect(() => {
    if (isEnded && !isPlaying) {
      setAutoPlay(false);
      const mssgContent = setTimeout(() => {
        resetBot();
      }, 2500);

      return () => {
        clearTimeout(mssgContent);
      };
    }
  }, [isEnded, isPlaying]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (transcript && renderMic) {
        stopRecognition();
        setVoiceRecognition(false);
        setTranscriptTxt("");
        setInputField(true);
        if (!isIOS()) {
          const data =
            searchInData(transcript).length > 0
              ? searchInData(transcript)
              : transcript;
              
          apiCall(data, true, "voice");
          setLoading(true);
          setMessages((prevChats: any) => [
            ...prevChats,
            {
              text: transcript,
              sender: "user",
              time: moment().format("h:mm A"),
            },
          ]);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [transcript]);

  useEffect(() => {
    if (chatMessageRef.current) {
      chatMessageRef.current.scrollTop = chatMessageRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    !isEnded && autoPlay && !isPlaying && startRecognition();
  }, [isPlaying]);

  useEffect(() => {
    pauseAudio();
    if (input && input.length > 0) {
      setMicBtn(false);
      setSendBtn(true);
    } else {
      setMicBtn(true);
      setSendBtn(false);
    }
  }, [input]);

  useEffect(() => {
    if (!active) {
      setTimeout(() => {
        setActive(true);
      }, 1000);
      return;
    }
    Lang(selectedLanguage);

    setMessages((prevChats: any) => [
      ...prevChats,
      {
        text: translations[selectedLanguage].greeting,
        sender: "bot",
        audio: audio[selectedLanguage],
        time: moment().format("h:mm A"),
      },

      {
        sender: "bot",
        text: `${translations[selectedLanguage].goodbye}`,
        content: (
          <>
            {schemeOptions &&
              schemeOptions !== undefined &&
              schemeOptions.map((val: any, index: any) => (
                <span key={index}>
                  <p className="schemesList" onClick={() => handleSchemes(val)}>
                    {val.val}
                  </p>
                </span>
              ))}
          </>
        ),
        time: moment().format("h:mm A"),
        end: false,
      },
    ]);
  }, [selectedLanguage]);

  useEffect(() => {
    RecommendSchemesApi(selectedLanguage, (res: any) => {
      res && res !== undefined && res.length > 0 && setAutoSuggScheme(res);
    });
  }, [selectedLanguage]);

  useEffect(() => {
    window.addEventListener(
      "blur",
      function () {
        pauseAudio();
      },
      false
    );
    const searchParams = new URLSearchParams(document.location.search);
    const language = searchParams.get("locale")
      ? searchParams.get("locale")
      : "en";

    language !== null && setSelectedLanguage(language);

    const options = [
      {
        val: translations[language].scheme,
        id: 1,
      },
      {
        val: translations[language].grievance,
        id: 2,
      },
      {
        val: (Hospitals as any)[language],
        id: 1,
      },
    ];
    setMessages([
      {
        sender: "bot",
        text: `${translations[language].greeting}
     `,
        time: moment().format("h:mm A"),
        audio: audio[language],
      },
      {
        sender: "bot",
        text: `${translations[language].goodbye}`,
        content: (
          <>
            {options &&
              options.map((val: any, index: any) => (
                <span key={index}>
                  <p className="schemesList" onClick={() => handleSchemes(val)}>
                    {val.val}
                  </p>
                </span>
              ))}
          </>
        ),
        time: moment().format("h:mm A"),
        // audio: greetingMessage(moment().format("HH:mm")).audio,
      },
    ]);
    setSelectedLanguage(language);
    playAudio(audio[language]);
  }, []);

  useEffect(() => {
    transcript.length > 0 && setTranscriptTxt(transcript);
    if (!listening && voiceRecognition) {
      playAudio(
        "https://coroverbackendstorage.blob.core.windows.net/chatbot-audio-bucket/caught.mp3"
      );
      SpeechRecognition.stopListening();
      isIOS() && recorder.saveRecording();
    }
  }, [transcript, listening]);

  return (
    <>
      {/* textMessages */}
      <Messages
        chatMessageRef={chatMessageRef}
        audioElementRef={audioElementRef}
        messages={messages}
        selectedLanguage={selectedLanguage}
        currentAudioUrl={currentAudioUrl}
        isPlaying={isPlaying}
        pauseAudio={pauseAudio}
        playAudio={playAudio}
        loading={loading}
      />

      {/* options for selection */}
      <SchemesOptions
        hideInput={hideInput}
        selectedLanguage={selectedLanguage}
        optionList={optionList}
        setHideInput={setHideInput}
        setInputField={setInputField}
        handleOptionClick={handleOptionClick}
        setBackOptions={setBackOptions}
        backOptions={backOptions}
        optionHeader={optionHeader}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        recognitionSupported={true}
        pauseAudio={pauseAudio}
        setVoiceRecognition={setVoiceRecognition}
        SpeechRecognition={SpeechRecognition}
        setRenderMic={setRenderMic}
        renderMic={renderMic}
      />

      {/* voice popup */}

      {voiceRecognition && listening && (
        <Voice
          setVoiceRecognition={setVoiceRecognition}
          setInputField={() => {
            !fileUpload ? setInputField(true) : setInputField(false);
          }}
          selectedLanguage={selectedLanguage}
          transcript={isIOS() ? "" : transcriptTxt}
          setTranscriptTxt={setTranscriptTxt}
          stopRecognition={stopRecognition}
        />
      )}

      {/* language selections*/}
      <LanguageOptions
        languageOptions={languageOptions}
        setLanguageOptions={setLanguageOptions}
        handleLanguageChange={handleLanguageChange}
        selectedLanguage={selectedLanguage}
      />

      {/* Address Options */}

      <AutoSuggestions
        input={input}
        setInput={setInput}
        selectedLanguage={selectedLanguage}
        autoPlay={autoPlay}
        apiCall={apiCall}
        setLoading={setLoading}
        setMessages={setMessages}
        autoInput={autoInput}
        autoSuggScheme={autoSuggScheme}
      />

      <HospitalOptions
        HospitalView={HospitalView}
        HospitalData={HospitalData}
        setHospitalView={setHospitalView}
        selectedLanguage={selectedLanguage}
        setSearchTerm={setSearchTerm}
      />

      {/* MicAndTextfield language,input, mic */}
      <MicAndTextfield
        inputField={inputField}
        setLanguageOptions={setLanguageOptions}
        selectedLanguage={selectedLanguage}
        input={input}
        handleSendMessage={handleSendMessage}
        setInput={setInput}
        micBtn={micBtn}
        recognitionSupported={true}
        startRecognition={startRecognition}
        requestPermission={requestPermission}
        sendBtn={sendBtn}
        getUserLocation={getUserLocation}
        fileUpload={fileUpload}
        handleFileUpload={handleFileUpload}
        location={location}
        setBackOptions={setBackOptions}
        backOptions={backOptions}
        setHideInput={setHideInput}
        setInputField={setInputField}
        setHospitalView={setHospitalView}
        setSearchTerm={setSearchTerm}
        setFileUpload={setFileUpload}
        setAutoPlay={setAutoPlay}
        apiCall={apiCall}
        setMessages={setMessages}
        setLoading={setLoading}
      />
    </>
  );
};

export default ChatContent;
