import { SearchOptions, OpentionsNotFound } from "../../Translation";
import React, { useEffect, useState } from "react";

import { IconButton } from "@mui/material";
import { Mic } from "../../Icons";
import useRecorder from "./recorder/useRecorder";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

const btnStyle = {
  backgroundColor: "#white",
  border: "1px solid #f5e6f5",
  borderRadius: "50%",
  padding: "5px",
  margin: "3px",
};
const btnStyle1 = {
  backgroundColor: "#A8DF8E",
  border: "1px solid #f5e6f5",
  borderRadius: "50%",
  padding: "5px",
  margin: "3px",
};
function SchemesOptions(props: any) {
  const [dropdownStyle, setDropDownStyle] = useState("dropDown");
  const [searchField, SetsearchField] = useState(false);
  const [blinking, setBlinking] = useState("");
  const [blinkBckGround, setBlinkBckGround] = useState(btnStyle);
  const { transcript: transcriptSearch, listening } = useSpeechRecognition();

  const {
    hideInput,
    selectedLanguage,
    optionList,
    setHideInput,
    setInputField,
    handleOptionClick,
    setBackOptions,
    optionHeader,
    searchTerm,
    setSearchTerm,
    recognitionSupported,
    pauseAudio,
    setVoiceRecognition,
    setRenderMic,
    renderMic,
  } = props;

  const filteredStatesOptions =
    optionList &&
    optionList.length > 0 &&
    optionList.filter((val: any) => {
      return val.label.toLowerCase().includes(searchTerm.toLowerCase());
    });
  const isIOS = () => {
    const platform = window.navigator.platform.toLowerCase();

    return (
      (platform.includes("mac") || platform.startsWith("ip")) &&
      selectedLanguage != "en" &&
      selectedLanguage != "hi"
    );
  };
  const recorder = useRecorder(() => {});

  const requestPermission = async () => {
    try {
      startRecognition();
      isIOS() && recorder.startRecording();
    } catch (error) {
      console.error("Permission denied:", error);
      alert("Error: " + error);
    }
  };

  const startRecognition = () => {
    setSearchTerm("");

    setRenderMic(false);
    pauseAudio();
    // setVoiceRecognition(true);
    SpeechRecognition.startListening({
      continuous: false,
      language: isIOS() ? `en-US` : `${selectedLanguage}-IN`,
    });
    isIOS() && recorder.startRecording();
    // setHideInput(false);
    setBlinking("blink");
    // setBlinkBckGround(btnStyle1);
  };

  const stopRecognition = () => {
    SpeechRecognition.stopListening();
    isIOS() && recorder.saveRecording();
    // setHideInput(true);
    setBlinking("");
    // setBlinkBckGround(btnStyle);
  };

  React.useEffect(() => {
    if (optionList && optionList.length < 5) {
      setDropDownStyle("dropDown_");
    } else {
      setDropDownStyle("dropDown");
    }

    if (optionList && optionList.length < 5) {
      SetsearchField(false);
    } else {
      SetsearchField(true);
    }
    setSearchTerm("");
    setBlinking("");
  }, [optionList]);

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (transcriptSearch && !renderMic) {
        setSearchTerm(transcriptSearch);
        stopRecognition();
        setInputField(true);
        if (!isIOS()) {
          setSearchTerm(transcriptSearch);
        }
      }
    }, 500);
    return () => {
      setSearchTerm("");
      clearTimeout(delayDebounceFn);
    };
  }, [transcriptSearch]);

  return (
    <React.Fragment>
      {hideInput && (
        <div className={dropdownStyle}>
          <div className="closeIcon">
            <img
              src="close.png"
              width={15}
              height={15}
              alt="close"
              className="close"
              onClick={() => {
                setHideInput(false);
                setInputField(false);
                setBackOptions(true);
                setSearchTerm("");
                setBlinking("");
              }}
            />
          </div>
          <span className="optionHeader">{optionHeader}</span>
          {searchField && (
            <div className="BoxSentMSGSchemes">
              <>
                <input
                  className="inputSchemes"
                  type="text"
                  placeholder={(SearchOptions as any)[selectedLanguage]}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />

                <IconButton
                  size="small"
                  style={blinkBckGround}
                  onClick={
                    recognitionSupported ? startRecognition : requestPermission
                  }
                >
                  <span className={blinking}> {Mic}</span>
                </IconButton>
              </>
            </div>
          )}

          <div style={{ overflowY: "scroll", height: "100%" }}>
            {filteredStatesOptions && filteredStatesOptions.length > 0 ? (
              filteredStatesOptions.map((val: any, index: number) => (
                <div className="optionContainer" key={index}>
                  <p
                    className="optionItem"
                    id={val.value}
                    onClick={() => handleOptionClick(val)}
                  >
                    {val.label.toUpperCase()}
                  </p>
                </div>
              ))
            ) : (
              <span>
                <div className="optionContainer">
                  <p className="optionItem">
                    {(OpentionsNotFound as any)[selectedLanguage]}
                  </p>
                </div>
              </span>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default SchemesOptions;
