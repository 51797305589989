import React, { useEffect, useState } from "react";
import { ListenResp, You, AxisPeople, Readmore } from "../../Translation";
import Loading from "../Loading/Loading";
import { IconButton } from "@mui/material";
import { ReadMoreButton } from "./Styles";
import DisLike from "../../Feedback/DisLike";
import Like from "../../Feedback/Like";

function Messages(props: any) {
  const [disLikeView, setDisLikeView] = useState(false);
  const [likeView, setLikeView] = useState(false);
  const [messageIndex, setMessageIndex] = useState<any>([]);
  const [answerId, setAnswerId] = useState<any>("");
  const {
    chatMessageRef,
    audioElementRef,
    messages,
    selectedLanguage,
    currentAudioUrl,
    isPlaying,
    pauseAudio,
    playAudio,
    loading,
  } = props;
  const schemeUrl = (url: any) => {
    if (url == "") return;
    // window.open(url, "_blank");
    window.postMessage({
      type: "REDIRECTION",
      data: url,
    });
  };

  const [toggledItems, setToggledItems] = useState<any[]>([]);

  const handleToggle = (index: number) => {
    setToggledItems((prevToggledItems) => [...prevToggledItems, index]);
  };

  return (
    <div className="ContentChat" ref={chatMessageRef}>
      <audio id="audio-element" ref={audioElementRef}></audio>
      {messages &&
        messages !== undefined &&
        messages.length > 0 &&
        messages.map((message: any, index: number) => (
          <React.Fragment key={index}>
            {message.sender === "user" && (
              <>
                <p className="timeuser">
                  {(You as any)[selectedLanguage]}, {message.time}
                </p>
                <div className={"user"} key={index}>
                  {message.text}
                </div>
              </>
            )}
            {message.sender === "bot" && (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // alignItems: "flex-end",
                  padding: "10px 10px 0px 10px",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  src="a_logo.png"
                  alt="icon"
                  width="20px"
                  height="20px"
                  // style={{ marginLeft: "-83px", marginBottom: "-15px" }}
                />
                <p className="timebot">
                  {(AxisPeople as any)[selectedLanguage]}, {message.time}
                </p>
                <div key={index} className={"bot"}>
                  <span>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: message.text.split("\\n")[0],
                      }}
                    ></div>

                    {message.knowMore !== undefined &&
                      message.knowMore !== "" && (
                        <ReadMoreButton
                          className="ReadMoreButton"
                          onClick={() => schemeUrl(message.knowMore)}
                        >
                          {(Readmore as any)[selectedLanguage]}
                        </ReadMoreButton>
                      )}

                    {message.content}
                  </span>
                </div>

                {message.sender === "bot" &&
                  message.audio &&
                  message.audio !== "" && (
                    <div className="bot-speaker">
                      <span>
                        {message.sender === "bot" &&
                        message.audio &&
                        currentAudioUrl === message.audio &&
                        isPlaying ? (
                          <>
                            <IconButton
                              size="small"
                              onClick={() => pauseAudio()}
                              style={{
                                fontSize: "22px !important",
                              }}
                            >
                              <img
                                src="stop.png"
                                alt="pause"
                                width={20}
                                height={20}
                              />
                            </IconButton>
                            {/* <span style={{ fontWeight: 500 }}>
                        {(ListenResp as any)[selectedLanguage]}
                      </span> */}
                          </>
                        ) : (
                          <>
                            <IconButton
                              size="small"
                              onClick={() => playAudio(message.audio)}
                              style={{
                                fontSize: "22px !important",
                              }}
                            >
                              <img
                                src="play.png"
                                alt="play"
                                width={20}
                                height={20}
                              />
                            </IconButton>
                            {/* <span style={{ fontWeight: 500 }}>
                        {(ListenResp as any)[selectedLanguage]}
                      </span> */}
                          </>
                        )}
                      </span>

                      {message.end && (
                        <div
                          style={{ padding: "5px" }}
                          className={
                            toggledItems.includes(index) ? "toggled" : ""
                          }
                          key={index}
                        >
                          <img
                            src="/dislike.svg"
                            alt="dislike"
                            width={20}
                            height={20}
                            onClick={() => {
                              setDisLikeView(true);
                              setMessageIndex(index);
                              setAnswerId(message.answerId);
                            }}
                          />
                          <img
                            src="/like.svg"
                            alt="like"
                            width={20}
                            height={20}
                            onClick={() => {
                              setLikeView(true);
                              setMessageIndex(index);
                              setAnswerId(message.answerId);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
              </div>
            )}
          </React.Fragment>
        ))}

      {loading && <Loading />}

      {disLikeView && (
        <DisLike
          handleToggle={handleToggle}
          setDisLikeView={setDisLikeView}
          disLikeView={disLikeView}
          messageIndex={messageIndex}
          selectedLanguage={selectedLanguage}
          answerId={answerId}
        />
      )}

      {likeView && (
        <Like
          likeView={likeView}
          setLikeView={setLikeView}
          handleToggle={handleToggle}
          messageIndex={messageIndex}
          selectedLanguage={selectedLanguage}
          answerId={answerId}
        />
      )}
    </div>
  );
}

export default Messages;
